import { ClientsService } from '~/services/ClientsService';
import * as Sentry from '@sentry/react';
import { logout } from '~/services/UserService';
import { useQuery } from '@tanstack/react-query';

const getUserProfile = async () => {
  const data = await ClientsService.getProfileInfo();
  if (!data) {
    throw new Error('Error getting user profile');
  }
  if (data.error) {
    if (data.error !== 'no session') {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to getProfileInfo()',
          file: 'UserProfile.jsx',
          error: data?.error,
          fn: 'componentDidMount()-01',
        });
        Sentry.captureException(new Error('getProfileInfo()'));
      });
    }
    if (data.error === 'no session') {
      // Redirect to homepage for login
      // @TODO use login API + login form layout
      logout(0);
      const currentPath = window.location.pathname + window.location.search;
      window.location.replace(`/?referer=${encodeURIComponent(currentPath)}`);
    }
    throw new Error(data.error);
  }
  return data[0];
};

export const useUserProfileQuery = () =>
  useQuery({ queryKey: ['userProfile'], queryFn: getUserProfile });
