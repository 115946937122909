import { Box, Typography } from '@mui/material';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import CustomLink from '~/components/Commons/Link/Link';
import errorPageStyles from '~/views/Pages/errorPageStyles';

export default function MfaPage() {
  const loaderData = useLoaderData() as { message: string };
  const [searchParams] = useSearchParams();
  const referer = searchParams.get('referer');
  return (
    <Box
      sx={[
        errorPageStyles.contentCenter,
        { gap: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' },
      ]}
    >
      <Typography variant="h1">Error logging in with 2FA</Typography>
      <Typography variant="h2">{loaderData.message}</Typography>
      <CustomLink to={`/login${referer ? `?referer=${referer}` : ''}`}>
        Click here to login again
      </CustomLink>
    </Box>
  );
}
