import React from 'react';
import useWindowSize from '~/hooks/useWindowSize';
import { Box } from '@mui/material';

import styles from './styles';

type PageHeaderProps = {
  children: React.ReactNode;
  sticky?: boolean;
  showAllChildrenOnMobile?: boolean;
  className?: string;
};

function PageHeader({ children, sticky, showAllChildrenOnMobile, className }: PageHeaderProps) {
  const { isMobile } = useWindowSize();
  // Converting to array before mapping incase object is returned from children prop.
  const childElements = React.Children.map(children, (child) => child);
  return (
    <>
      {showAllChildrenOnMobile ||
      !isMobile ||
      (isMobile && childElements && childElements.length >= 2) ? (
        <Box
          sx={{ ...styles.container, ...(sticky && styles.sticky) }}
          style={
            sticky
              ? {
                  top: '0px',
                  width: '100%',
                  position: 'sticky',
                  display: 'flex',
                  justifyContent: 'space-between',
                }
              : {}
          }
          className={className}
        >
          <Box sx={styles.inner}>
            {childElements && childElements[1] && isMobile && !showAllChildrenOnMobile
              ? childElements[1]
              : children}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

/**
 * The `<PageHeader />` component is used to display a header for a page.
 * It has some specific behaviour for mobile devices.
 * i.e. on desktop it will display all children, but on mobile it will
 * only display the children[1] if it exists.
 * this is because the children[0] is almost always the title
 * and children[1] is the page controls (e.g. download csv, save buttons etc).
 * On Mobile we don't want the title to be displayed, as it is in the navbar already.
 * If in any event we want to show all children on Mobile devices we can add the showAllChildrenOnMobile prop
 * to the component
 * @example
 * <PageHeader>
 *  <Title>Title of Page</Title> // this wont display on mobile
 *  <Button>Download CSV</Button> // but this will
 * </PageHeader>
 * @example
 * // on mobile none of this will display
 * <PageHeader>
 * <Title>Title of Page</Title>
 * </PageHeader>
 * you can overwrite this behaviour by passing the prop showAllChildrenOnMobile as true.
 * @example
 * <PageHeader showAllChildrenOnMobile>
 *  <Title>Title of Page</Title> // this will display on mobile
 * </PageHeader>
 */

export default PageHeader;
