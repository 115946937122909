/* eslint-disable no-use-before-define */
import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Outlet } from 'react-router-dom';
import LoadingProgress from '~/components/Commons/LoadingProgress';
import GlobalNotification from '~/components/Commons/Notification/GlobalNotification';
import { NotificationsProvider } from '~/context/Notifications';
import { MenuProvider } from '~/context/useMiniMenu';
import globalStyles from '~/globalStyles';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';
import theme from '~/theme';
import ErrorBoundaryPage from '~/views/Pages/ErrorBoundaryPage';
import UnderMaintenancePage from '~/views/Pages/UnderMaintenancePage';
import GlobalDialog from './components/Commons/ActionConfirmDialog/GlobalDialog';
import { SessionPlQueryReturnData } from './services/ClientServiceTS';

export default function App() {
  return (
    <CookiesProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <NotificationsProvider>
            <Sentry.ErrorBoundary
              showDialog
              // eslint-disable-next-line react/jsx-props-no-spreading
              fallback={(fallbackProps) => <ErrorBoundaryPage {...fallbackProps} />}
            >
              <MenuProvider>
                <LoadingHandler />
              </MenuProvider>
              <GlobalDialog />
              <GlobalNotification />
            </Sentry.ErrorBoundary>
          </NotificationsProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CookiesProvider>
  );
}

function LoadingHandler() {
  const { isLoading, data: isUnderMaintenance } = useSessionPlQuery(
    React.useCallback((d: SessionPlQueryReturnData) => {
      return !!d.maintenance || process.env.REACT_APP_UNDER_MAINTENANCE === 'true';
    }, []),
  );

  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        throwOnError: (error, query) => {
          if (error instanceof Error && error.cause instanceof Response) {
            return (
              query?.options?.queryKey?.[0] === 'session.pl' && Number(error?.cause?.status) >= 500
            );
          }
          return false;
        },
      },
    });
  }, [queryClient]);

  if (isUnderMaintenance) {
    return (
      <React.Suspense fallback={<LoadingProgress loading />}>
        <UnderMaintenancePage />
      </React.Suspense>
    );
  }

  if (isLoading) {
    return <LoadingProgress loading />;
  }
  return (
    <React.Suspense fallback={<LoadingProgress loading />}>
      <Outlet />
    </React.Suspense>
  );
}
