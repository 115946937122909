import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { useAccountParams } from '~/hooks/useAccountParams';
import { customFetch } from '~/utils/customFetch';

export type SupplyManagerPartnerData = {
  id: number;
  name: string | null;
  logo: string | null;
  domain: string | null;
  isBlocked: boolean;
  category: string | null;
};

type Formats = 'png' | 'jpg';

type ImageSize = `${number}x${number}.${Formats}`;

export function partnersWithLogoQueryOptions({
  imageSize = '100x30.png',
  accountId,
}: { imageSize?: ImageSize; accountId?: number } = {}) {
  return {
    queryKey: ['partners-with-logo', imageSize, accountId],
    queryFn: async () => {
      const res = await customFetch<{ data: SupplyManagerPartnerData[] }>(
        `/authenticated/client/${accountId}/supply-manager`,
        {
          useNodeEndpoint: true,
        },
      );
      return res.data.data.map((partner) => ({
        ...partner,
        logo: partner.logo
          ? `${process.env.REACT_APP_ENVIRONMENT !== 'prod' ? '/preview' : ''}${
              partner.logo
            }/${imageSize}`
          : null,
      }));
    },
  } satisfies QueryObserverOptions;
}

export function usePartnersWithLogoQuery(imageSize: ImageSize = '100x30.png') {
  const accountParams = useAccountParams();
  return useQuery(partnersWithLogoQueryOptions({ imageSize, accountId: accountParams?.accountId }));
}
