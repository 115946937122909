/* eslint-disable no-use-before-define */
import {
  ResolvedClientSessionData,
  ResolvedPartnerSessionData,
} from '../hooks/queries/useSessionPlQuery';

type Perm = {
  sessionPlData?: ResolvedClientSessionData | ResolvedPartnerSessionData;
};

type PermFunction = ({ sessionPlData }: Perm) => boolean;

export const perms = {
  accountParameters: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData?.permissions.self_serve,
  accountRules: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData?.permissions.self_serve,
  accountSettings: ({ sessionPlData }) => !!sessionPlData?.permissions.dianomi,
  accountUsers: ({ sessionPlData }) => !!sessionPlData,
  adForm: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData?.permissions.self_serve,
  adManager: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData?.permissions.self_serve,
  analytics: ({ sessionPlData }) => !!sessionPlData?.permissions.campaign,
  billing: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData?.nav.billing,
  conversionTrackers: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData.permissions.self_serve,
  insights: ({ sessionPlData }) => !!sessionPlData?.nav.insights,
  invoiceRoute: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData?.nav.billing,
  isDianomi: ({ sessionPlData }) => !!sessionPlData?.login.is_dianomi,
  leads: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData?.nav.myLeads,
  newClient: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' && !!sessionPlData?.permissions.allow_create_client,
  overview: ({ sessionPlData }) => !!sessionPlData?.permissions.campaign,
  publisherAds: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'partner' && !!sessionPlData?.permissions.ads,
  publisherPaymentHistory: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'partner' && !!sessionPlData?.permissions.campaign,
  loggedIn: ({ sessionPlData }) => !!sessionPlData,
  audienceManager: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' &&
    (!!sessionPlData.permissions.showAudienceManager || !!sessionPlData.permissions.cohorts) &&
    !!sessionPlData.permissions.self_serve,
  supplyManager: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' &&
    !!sessionPlData.permissions.self_serve &&
    sessionPlData.permissions.allowSupplyManager === 1,
  contextfeedManager: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'partner' && !!sessionPlData.permissions.allowContextfeedManager,
  deviceBidManagerClient: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' &&
    !!sessionPlData?.permissions.device_manager?.bid.match(/client|all/) &&
    !!sessionPlData?.permissions.self_serve,
  deviceBidManagerProduct: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' &&
    !!sessionPlData?.permissions.device_manager?.bid.match(/product|all/) &&
    !!sessionPlData?.permissions.self_serve,
  pubBidManagerClient: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' &&
    !!sessionPlData?.permissions.publisher_manager?.bid.match(/client|all/) &&
    !!sessionPlData?.permissions.self_serve,
  pubBidManagerProduct: ({ sessionPlData }) =>
    sessionPlData?.accountType === 'client' &&
    !!sessionPlData?.permissions.publisher_manager?.bid.match(/product|all/) &&
    !!sessionPlData?.permissions.self_serve,
} as const satisfies Record<string, PermFunction>;
