/* eslint-disable no-use-before-define */
import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useNotifications from '~/context/Notifications';
import { customFetch } from '~/utils/customFetch';

export function useGetOtpAuthUrlMutation() {
  const queryClient = useQueryClient();
  const { setTimedNotification } = useNotifications();
  return useMutation({
    mutationKey: ['get-otp-auth-url'],
    mutationFn: () =>
      customFetch<SetupOTPResponseData>('/auth/totp-setup', {
        method: 'POST',
        body: JSON.stringify({
          step: 1,
        }),
        useNodeEndpoint: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (!res.data.success) {
          throw new Error('Failed to get Authenticator setup URL');
        }
        return res.data.data;
      }),
    onError: () => {
      setTimedNotification(
        'danger',
        'Failed to generate two-factor authentication (2FA) setup code. Please try again.',
        5000,
      );
    },
    onSettled: () => {
      return queryClient.invalidateQueries(getLoginDetailsQuery());
    },
  });
}

type SetupOTPResponseData =
  | {
      success: true;
      data: { otpAuthUrl: string };
    }
  | {
      success: false;
    };

export function useSetupOtpMutation() {
  const queryClient = useQueryClient();
  const { setTimedNotification } = useNotifications();
  return useMutation({
    mutationKey: ['setup-otp'],
    mutationFn: (otp: string) =>
      customFetch<{ success: true; data: string[] } | { success: false }>('/auth/totp-setup', {
        method: 'POST',
        body: JSON.stringify({
          step: 2,
          otpCode: otp,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        useNodeEndpoint: true,
      }).then((res) => {
        if (!res.data.success) {
          throw new Error('Failed to setup OTP');
        }
        return res.data;
      }),
    onError: () => {
      setTimedNotification(
        'danger',
        'Failed to setup two-factor authentication (2FA). Please verify the code and try again.',
        5000,
      );
    },
    onSettled: () => {
      return queryClient.invalidateQueries(getLoginDetailsQuery());
    },
  });
}

function getLoginDetailsQuery() {
  return queryOptions({
    queryKey: ['login-details'],
    queryFn: () =>
      customFetch<
        | { success: false }
        | {
            success: true;
            data: {
              isMfaSetup: boolean;
              mfaType: 'none' | 'totp' | 'email';
            };
          }
      >('/auth/login-details', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        useNodeEndpoint: true,
      }).then((res) => {
        if (!res.data.success) {
          throw new Error('Failed to get login details');
        }
        return res.data.data;
      }),
  });
}

export function useGetLoginDetails() {
  return useQuery(getLoginDetailsQuery());
}

export function useRemoveOtpMutation() {
  const queryClient = useQueryClient();
  const { setTimedNotification } = useNotifications();
  return useMutation({
    mutationKey: ['remove-otp'],
    mutationFn: ({ otp }: { otp: string }) =>
      customFetch<{ success: true } | { success: false; message: string }>('/auth/remove-totp', {
        method: 'POST',
        body: JSON.stringify({
          otpCode: otp,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        useNodeEndpoint: true,
        throwOnHTTPError: false,
      }).then((res) => {
        if (!res.data.success) {
          throw new Error(res.data.message);
        }
        return res.data;
      }),
    onError: (e) => {
      const errorMessage = e.message.toLowerCase().includes('mfa')
        ? 'Unable to remove two-factor authentication (2FA). You have access to an account with 2FA required. Please contact your account manager if you need to remove 2FA.'
        : 'Failed to remove two-factor authentication (2FA)';
      setTimedNotification('danger', errorMessage, 10000);
    },
    onSettled: () => {
      return queryClient.invalidateQueries(getLoginDetailsQuery());
    },
  });
}
