import * as Sentry from '@sentry/react';
import { ClientsService } from '~/services/ClientsService';

const mapAnalyticRoutes = (analyticRoutes) => {
  const linkObject = {};
  Object.values(analyticRoutes).forEach((product) => {
    if (product.links) {
      Object.values(product.links).forEach((linkId) => {
        linkObject[`${product.name} ${linkId}`] = {
          name: `${product.name} ${linkId}`,
          linked: true,
          hidden_fields: 0,
          shortcode: linkId,
          periods: product.periods,
          takes_product: 0,
          visible_to_client: 0,
          visible_to_partner: 0,
          isLink: true,
        };
      });
    }
  });
  return { ...analyticRoutes, ...linkObject };
};

const handleGetGenieNav = async ({ id, isPartner, stats }) => {
  const genieNav = await ClientsService.getGenieNav(id, isPartner);
  if (genieNav && !genieNav.error && stats) {
    const newStatsNav = {};
    Object.values(stats).forEach((shortcode) => {
      if (shortcode && genieNav[shortcode]) {
        newStatsNav[shortcode] = genieNav[shortcode];
      }
    });
    return mapAnalyticRoutes(newStatsNav);
  }
  if (genieNav && genieNav.error && genieNav.error === 'no permissions') {
    throw new Error('no permissions', { cause: genieNav });
  }
  if (genieNav && genieNav.error) {
    throw new Error('generic error', { cause: genieNav });
  }
  return null;
};

const handleGetAccounts = async () => {
  const accountsData = await ClientsService.getAccounts();
  if (!accountsData || accountsData.error) {
    if (accountsData.error !== 'no valid session') {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'no accountsData or accountsData.error from getAccounts()',
          file: 'Admin.jsx',
          error: accountsData?.error,
          fn: 'handleGetAccounts()-01',
        });
        Sentry.captureException(new Error('getAccounts()'));
      });
    }
    return null;
  }
  const emptyAccounts =
    accountsData.length === 0 ||
    (accountsData.clients.length === 0 && accountsData.partners.length === 0);

  return { accounts: accountsData, emptyAccounts };
};

export const isPlainObject = (obj) => obj && !Array.isArray(obj) && typeof obj === 'object';

const utils = {
  handleGetGenieNav,
  handleGetAccounts,
  isPlainObject,
};

export default utils;
