/* eslint-disable no-console */
export default function loadDevTools(callback) {
  try {
    const devToolsInSearch = new URL(window.location.href).searchParams.get('dev-tools');
    const explicitlyDisabled =
      devToolsInSearch === 'false' || window.localStorage.getItem('dev-tools') === 'false';

    const explicitlyEnabled =
      devToolsInSearch === 'true' || window.localStorage.getItem('dev-tools') === 'true';

    if (
      !explicitlyDisabled &&
      (process.env.REACT_APP_ENVIRONMENT !== 'prod' || explicitlyEnabled)
    ) {
      console.log('Loading dev tools...');
      // use a dynamic import so the dev-tools code isn't bundled with the regular
      // app code so we don't worry about bundle size.
      import('./dev-tools').then((devTools) => devTools.install()).finally(callback);
    } else {
      // if we don't need the DevTools, call the callback immediately.
      callback();
    }
  } catch {
    callback();
  }
}
