/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Link, NavLinkProps, type LinkProps, NavLink } from 'react-router-dom';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';
import packageJSON from '~/../package.json';

/**
 * This link will reload the page on click _if_ the version doesn't match the api version.
 */
export default React.forwardRef(
  (props: LinkProps, ref: React.Ref<HTMLAnchorElement> | undefined) => {
    const sessionQuery = useSessionPlQuery((data) => data.version);
    return (
      /* eslint-disable-next-line react/forbid-elements */
      <Link
        reloadDocument={
          // don't reload on dev as the version never matches right

          process.env.REACT_APP_ENVIRONMENT === 'prod' &&
          !!sessionQuery.data &&
          sessionQuery.data !== packageJSON.version
        }
        {...props}
        ref={ref}
      />
    );
  },
);

/**
 * This link will reload the page on click _if_ the version doesn't match the api version.
 */
export const CustomNavLink = React.forwardRef(
  (props: NavLinkProps, ref: React.Ref<HTMLAnchorElement> | undefined) => {
    const sessionQuery = useSessionPlQuery((data) => data.version);
    const { style } = props;
    return (
      /* eslint-disable-next-line react/forbid-elements */
      <NavLink
        reloadDocument={
          // don't reload on dev as the version never matches right
          process.env.REACT_APP_ENVIRONMENT === 'prod' &&
          !!sessionQuery.data &&
          sessionQuery.data !== packageJSON.version
        }
        {...props}
        style={style}
        ref={ref}
      />
    );
  },
);
