/* eslint-disable react/jsx-props-no-spreading */
import { zodResolver } from '@hookform/resolvers/zod';
import { CheckCircleOutline, ContentCopy, Download, VisibilityOff } from '@mui/icons-material';
import { Box, Button, ButtonProps, Typography, styled } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useBlocker } from 'react-router-dom';
import { z } from 'zod';
import ActionConfirmDialog from '~/components/Commons/ActionConfirmDialog';
import DianomiSpinner from '~/components/Commons/DianomiSpinner/DianomiSpinner';
import CustomLink from '~/components/Commons/Link/Link';
import TextField from '~/components/Form/TextField/TextField';
import useNotifications from '~/context/Notifications';
import useReportDownloadMutation from '~/hooks/queries/useReportDownloadMutation';
import {
  useGetLoginDetails,
  useGetOtpAuthUrlMutation,
  useRemoveOtpMutation,
  useSetupOtpMutation,
} from './hooks';

const SecretCodesWrapper = styled(Box)<{ isBlurred: boolean }>(({ isBlurred }) => ({
  cursor: isBlurred ? 'pointer' : 'default',
  position: 'relative',
  filter: isBlurred ? 'blur(6px)' : 'none',
  transition: 'filter 0.2s ease',
  minWidth: '370px',
  fontVariantNumeric: 'tabular-nums',
  '& .code': {
    fontFamily: 'monospace',
    backgroundColor: '#f5f5f5',
    padding: '8px 16px',
    borderRadius: '4px',
    display: 'inline-block',
    userSelect: isBlurred ? 'none' : 'text',
  },
}));

function CustomButton({ children, isLoading, ...props }: ButtonProps & { isLoading?: boolean }) {
  return (
    <Button variant="outlined" disabled={isLoading} {...props}>
      {children}
    </Button>
  );
}

function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '800px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

const NOTIFICATION_DURATION = 3000;
const ERROR_MESSAGES = {
  LOAD_ERROR: 'There was an error loading Security details. Please try again.',
  INVALID_OTP: 'Invalid Authentication Code. Please try again.',
  COPY_FAILED: 'Failed to copy backup codes',
  DOWNLOAD_FAILED: 'Failed to download backup codes',
} as const;

const SUCCESS_MESSAGES = {
  COPIED: 'Backup codes copied to clipboard',
  DOWNLOADED: 'Backup codes downloaded successfully',
} as const;

export function SetupMFA() {
  const loginDetails = useGetLoginDetails();
  const getOtpAuthUrlMutation = useGetOtpAuthUrlMutation();
  const setupOtpMutation = useSetupOtpMutation();

  if (loginDetails.isLoading || getOtpAuthUrlMutation.isPending) {
    return (
      <Wrapper>
        <DianomiSpinner loading />
      </Wrapper>
    );
  }

  if (loginDetails.error) {
    return (
      <Wrapper>
        <Typography variant="h2">{ERROR_MESSAGES.LOAD_ERROR}</Typography>
      </Wrapper>
    );
  }

  const hasMFASetupAlready = loginDetails.data?.isMfaSetup && loginDetails.data?.mfaType === 'totp';

  if (setupOtpMutation.isSuccess) {
    return <ActionSuccess actionData={setupOtpMutation.data} />;
  }

  if (hasMFASetupAlready) {
    return <RemoveMFA />;
  }

  if (!getOtpAuthUrlMutation.data) {
    return (
      <Wrapper>
        <Typography variant="h2">Setup two-factor authentication (2FA)</Typography>
        <Typography variant="h3">
          To use 2FA, you need to set up an authenticator app on your device.
        </Typography>
        <Typography variant="body1">
          First, please download an 2FA Authentication App. This can be any third party
          authenticator app, for example Google Authenticator{' '}
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
            target="_blank"
            rel="noopener noreferrer"
          >
            (Android)
          </a>{' '}
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            target="_blank"
            rel="noopener noreferrer"
          >
            (iOS)
          </a>{' '}
          or 2FA Authenticator{' '}
          <a
            href="https://play.google.com/store/apps/details?id=com.twofasapp&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            (Android)
          </a>{' '}
          <a
            href="https://apps.apple.com/us/app/2fa-authenticator-2fas/id1217793794"
            target="_blank"
            rel="noopener noreferrer"
          >
            (iOS)
          </a>
          .
        </Typography>
        <CustomButton
          onClick={() => getOtpAuthUrlMutation.mutate()}
          isLoading={getOtpAuthUrlMutation.isPending}
        >
          Start Setup
        </CustomButton>
      </Wrapper>
    );
  }
  return (
    <>
      <Wrapper>
        <Typography variant="h3">Scan the QR code below with your authenticator app</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <QRCode value={getOtpAuthUrlMutation.data?.otpAuthUrl} />
        </Box>
        <Typography variant="h3">
          And finally, enter the code below to complete the setup.
        </Typography>
        <OTPForm onSubmit={(otp) => setupOtpMutation.mutate(otp)} />
        {setupOtpMutation.isError ? (
          <Typography variant="body1" color="error">
            {ERROR_MESSAGES.INVALID_OTP}
          </Typography>
        ) : null}
      </Wrapper>
    </>
  );
}

const useOtpForm = () => {
  return useForm({
    defaultValues: {
      otp: '',
    },
    resolver: zodResolver(z.object({ otp: z.string().min(1, 'Authentication Code is required') })),
  });
};

export function OTPForm({
  onSubmit,
  buttonText,
  autoFocus = true,
  isLoading = false,
}: {
  onSubmit: (otp: string) => void;
  buttonText?: string;
  autoFocus?: boolean;
  isLoading?: boolean;
}) {
  const form = useOtpForm();
  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'baseline' }}
      onSubmit={form.handleSubmit((data) => onSubmit(data.otp))}
    >
      <Controller
        control={form.control}
        name="otp"
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Unique Authentication Code"
            id="otp"
            autoFocus={autoFocus}
            error={!!error}
            errorText={error?.message}
            disabled={isLoading}
            sx={{ flex: 1 }}
          />
        )}
      />
      <CustomButton
        type="submit"
        disabled={form.formState.isSubmitting || isLoading}
        isLoading={isLoading}
        onClick={form.handleSubmit((data) => onSubmit(data.otp))}
      >
        {buttonText || 'Submit'}
      </CustomButton>
    </Box>
  );
}

export function RemoveMFA() {
  const removeOtpMutation = useRemoveOtpMutation();

  return (
    <Wrapper>
      <Typography variant="h2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        Two-factor authentication (2FA) setup complete <CheckCircleOutline color="success" />
      </Typography>
      <Typography variant="body1">
        You have already setup 2FA. You can attempt to remove it here, but if you have access to an
        account with 2FA required, it will fail. Please contact your account manager if you need to
        remove 2FA.
      </Typography>
      <OTPForm
        onSubmit={(otp) => removeOtpMutation.mutate({ otp })}
        buttonText="Remove 2FA"
        autoFocus={false}
      />
      <Typography variant="body1">
        Once removed, make sure you remove the account from your authenticator app.
      </Typography>
    </Wrapper>
  );
}

function ActionSuccess({ actionData }: { actionData: { success: true; data: string[] } }) {
  const { setTimedNotification } = useNotifications();
  const [isCodesBlurred, setIsCodesBlurred] = React.useState(true);
  const queryClient = useQueryClient();
  const downloadCodes = useReportDownloadMutation();

  const [isCodeSaved, setIsCodeSaved] = React.useState(false);

  const blocker = useBlocker(!isCodeSaved);

  React.useEffect(() => {
    // useBlocker only works on RR events/links, so we need to use a listener to block the
    // beforeunload event, ie refresh or back button
    function listener(e: BeforeUnloadEvent) {
      if (!isCodeSaved) {
        e.preventDefault();
        e.returnValue = '';
      }
    }
    window.addEventListener('beforeunload', listener);
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [isCodeSaved]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(actionData.data.join('\n'));
      setTimedNotification('success', SUCCESS_MESSAGES.COPIED, NOTIFICATION_DURATION);
      setIsCodeSaved(true);
      queryClient.invalidateQueries({ queryKey: ['user-profile'] });
    } catch (error) {
      setTimedNotification('danger', ERROR_MESSAGES.COPY_FAILED, NOTIFICATION_DURATION);
    }
  };

  return (
    <Wrapper>
      <Typography variant="h2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        Two-factor authentication (2FA) setup successful <CheckCircleOutline color="success" />
      </Typography>
      <Typography variant="subtitle2">Your backup codes are:</Typography>
      <SecretCodesWrapper
        isBlurred={isCodesBlurred}
        onClick={(e) => {
          e.stopPropagation();
          setIsCodesBlurred(false);
        }}
        title={isCodesBlurred ? 'Click anywhere to reveal codes' : undefined}
        sx={{ mt: 2 }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '8px' }}>
          {actionData.data.map((code, index) => (
            <React.Fragment key={code}>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'right',
                  fontVariantNumeric: 'tabular-nums',
                  userSelect: 'none',
                }}
              >
                {index + 1}
              </Typography>
              <Typography variant="body1">{code}</Typography>
            </React.Fragment>
          ))}
        </Box>
      </SecretCodesWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          mr: 1,
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            mt: 1,
            maxWidth: '350px',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            textWrap: 'pretty',
          }}
        >
          {isCodesBlurred
            ? 'Click anywhere to reveal backup codes'
            : 'Save these codes in a secure location. You will need them to log in if you lose access to your authenticator app.'}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mr: 1 }}>
          {!isCodesBlurred ? (
            <Button
              startIcon={<VisibilityOff />}
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setIsCodesBlurred(true);
              }}
            >
              Hide codes
            </Button>
          ) : null}
          <Button variant="outlined" startIcon={<ContentCopy />} onClick={handleCopy}>
            Copy
          </Button>
          <Button
            variant="outlined"
            startIcon={<Download />}
            onClick={() =>
              downloadCodes.mutate(
                {
                  data: actionData.data.map((code) => ({ code })),
                  reportName: 'MyDianomi Authenticator Backup Codes',
                  setColumns: ['code'],
                },
                {
                  onSuccess: () => {
                    setIsCodeSaved(true);
                    setTimedNotification(
                      'success',
                      SUCCESS_MESSAGES.DOWNLOADED,
                      NOTIFICATION_DURATION,
                    );
                  },
                  onError: () => {
                    setTimedNotification(
                      'danger',
                      ERROR_MESSAGES.DOWNLOAD_FAILED,
                      NOTIFICATION_DURATION,
                    );
                  },
                },
              )
            }
          >
            Download
          </Button>
          <Button variant="outlined" component={CustomLink} to="/" reloadDocument>
            Done
          </Button>
        </Box>
      </Box>

      <ActionConfirmDialog
        open={blocker?.state === 'blocked'}
        handleDialogClose={() => {
          blocker?.reset?.();
        }}
        title="Are you sure you want to leave this page?"
        message="You will lose your codes."
        handleDialogConfirm={() => {
          blocker?.proceed?.();
        }}
      />
    </Wrapper>
  );
}
