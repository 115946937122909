/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { InputAdornment, TextField as Field, TextFieldProps as FieldProps } from '@mui/material';
import theme from '~/theme';

export type TextFieldProps = {
  defaultValue?: string;
  required?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  name: string;
  id: string;
  isValid?: boolean;
  warning?: boolean;
  label?: React.ReactNode;
  error?: boolean;
  errorText?: React.ReactNode;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  maxLength?: number;
} & Omit<FieldProps, 'prefix'>;

const TextField = React.forwardRef(
  (
    {
      defaultValue,
      required,
      onBlur,
      onFocus,
      name,
      id,
      isValid,
      warning,
      label,
      error,
      errorText,
      sx,
      prefix,
      suffix,
      disabled,
      ...rest
    }: TextFieldProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      onBlur?.(e);
    };

    const focusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      onFocus?.(e);
    };

    return (
      <Field
        defaultValue={defaultValue}
        required={required}
        sx={{
          width: '100%',
          '& .MuiInputBase-root > fieldset': {
            ...(isValid && {
              borderColor: 'success.main',
              borderWidth: '2px',
            }),
            ...(warning && {
              borderColor: 'warning.light',
              borderWidth: '2px',
            }),
            ...(error && {
              borderColor: 'error.main',
              borderWidth: '2px',
            }),
          },
          '& .MuiFormHelperText-root': {
            ...(error && theme.defaultStyles.errorContainer),
            ...(warning && theme.defaultStyles.warningContainer),
          },
          ...sx,
        }}
        inputRef={ref}
        onFocus={focusHandler}
        onBlur={blurHandler}
        name={name}
        id={id}
        label={label}
        error={error}
        margin="dense"
        helperText={errorText}
        size="small"
        disabled={disabled}
        {...rest}
        slotProps={{
          input: {
            startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
            endAdornment: suffix && <InputAdornment position="end">{suffix}</InputAdornment>,
            ...rest.InputProps,
          },

          inputLabel:
            !rest.value && prefix && !isFocused
              ? {
                  shrink: false,
                  sx: { paddingLeft: typeof prefix === 'string' ? `${prefix.length}rem` : '2rem' },
                }
              : {},
        }}
      />
    );
  },
);

TextField.defaultProps = {
  defaultValue: undefined,
  required: false,
  error: false,
  isValid: false,
  errorText: '',
  warning: false,
  onFocus: () => null,
  onBlur: () => null,
  suffix: undefined,
  label: null,
  prefix: null,
};

export default TextField;
