import { Theme } from '@mui/material/styles';
import { mqFromDesktop } from '~/variables/breakpoints';

export default {
  container: {
    minHeight: '70px',
    padding: '15px',
    width: '100%',
    zIndex: '100',
    background: (theme: Theme) => theme.defaultStyles?.header.backgroundColor,
    borderBottom: (theme: Theme) => theme.defaultStyles?.header?.borderBottom,
    borderLeft: (theme: Theme) => theme.defaultStyles?.header?.borderLeft,
    top: '40px',
    [mqFromDesktop]: {
      paddingLeft: '30px',
      paddingRight: '30px',
      top: '65px',
    },
  },
  inner: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    [mqFromDesktop]: {
      flexDirection: 'row',
      textAlign: 'initial',
      justifyContent: 'space-between',
      flexWrap: 'unset',
    },
  },
  sticky: {
    zIndex: 2000,
    backdropFilter: 'blur(3px)',
  },
};
