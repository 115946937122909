import { QueryCache, QueryClient } from '@tanstack/react-query';

export const globalClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof Error && error.cause instanceof Response && error.cause.status === 401) {
        window.location.replace('/no-session');
      }
    },
  }),
});

globalClient.setQueryDefaults(['geoData', 'internalStore'], {
  gcTime: Infinity,
  staleTime: Infinity,
});
