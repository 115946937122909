import { useMutation } from '@tanstack/react-query';
import useNotifications from '~/context/Notifications';
import { Daum, downloadXLXS } from '~/views/Dashboard/Analytics/utils';

type UseReportDownloadMutationProps = {
  data: Daum[] | undefined;
  setColumns?: string[];
  from?: string;
  to?: string;
  reportName: string;
};

const formatColumnName = (column: string) =>
  column
    .replace(/([A-Z])/, ' $1')
    .trim()
    .replace(/\b\w/g, (char) => char.toUpperCase());

function useReportDownloadMutation() {
  const { setTimedNotification } = useNotifications();

  return useMutation({
    mutationFn: async ({
      data,
      setColumns,
      from,
      to,
      reportName,
    }: UseReportDownloadMutationProps) => {
      if (!data) {
        return;
      }

      const columns = setColumns ?? Object.keys(data[0]);
      const formattedColumns = columns.map((key) => formatColumnName(key));

      // Removes excluded columns if provided
      const reportData = setColumns
        ? data.map((item) => {
            const filteredItem: Partial<Daum> = {};
            setColumns.forEach((key) => {
              if (key in item) {
                const formattedKey = formatColumnName(key);
                filteredItem[formattedKey] = item[key];
              }
            });
            return filteredItem;
          })
        : data;

      await downloadXLXS(reportData, formattedColumns, from ?? '', to ?? '', reportName);
    },
    onError: () => {
      setTimedNotification('danger', 'There was an error downloading the report.', 5000);
    },
  });
}

export default useReportDownloadMutation;
