import { Box } from '@mui/material';

import DashboardContainer from '../../components/Commons/DashboardContainer';
import PageHeader from '../../components/Commons/PageHeader';
import PageHeaderTitle from '../../components/Typography/PageHeaderTitle';
import { SetupMFA } from './profile-security/setup-otp';

export default function SetupMFAPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <PageHeader>
        <PageHeaderTitle>Setup two-factor authentication (2FA)</PageHeaderTitle>
      </PageHeader>
      <DashboardContainer sx={{ alignSelf: 'center', width: '100%' }}>
        <SetupMFA />
      </DashboardContainer>
    </Box>
  );
}
